.page_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.admin_container{
    width: 100%;
    padding-inline: 2rem;
    box-sizing: border-box;
}

.table_Container{
    margin-top: 2rem;
}

.tabs_container{
    margin-bottom: 40px;
}

.admin_pipeline_container{
    display: flex;
    gap: 1rem;
}

.hyperlink{
    font-size: 14px;
    color: var(--font-color-primary);

    text-decoration: underline;
    text-decoration-color: var(--accent-color);
    transition: all 0.25s linear;
    position: relative;
}

.hyperlink:hover{
    text-decoration-color: var(--accent-color-dark);
}
