.tooltip_container{
    height: 220px;
    width: 300px;
    border: thin rgb(16, 14, 14) solid;
    border-radius: 7px;
    background-color: whitesmoke;
    padding: 0.5rem;
}
.tooltip_log_list{
    display: flex;
    font-size: small;
    width: 100%;
    height: 100px;
    overflow-y:auto;
    padding-inline-start: 5px;
}

.tooltip_log_type{
    font-size: medium;
}

.common_input_container{
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.custom-header-container{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex: 1;
}
.custom-header-label{
    box-sizing: border-box;
    font-size: 15px;
    width: 100%;
    text-align: center;
    border-bottom: solid thin black;
}

.cutom-header-input{
    flex: 0.6;
}

.custom-header-input-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-content: flex-start;
    flex-wrap: nowrap;
    align-items: center;
}

.information_light{
    color: var(--fontColorPrimaryLight);
    margin-top: 10px;
}

.divider{
    width: 80%;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.sub_details_section_container{
    border: solid #5a8c9c;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 1rem;
    background-color: #6be5ec0f;
    height: 100%;
}

.header{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    color: white;
    font-size: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    box-sizing: border-box;
    background: var(--accent-color-dark);
    gap: 20px;
    padding-inline: 40px;
    width: 100%;
}

.router_link{
    font-size: 1.2rem;
    text-decoration: none;
    color: var(--font-color-primary);

    border-bottom: 3px solid var(--accent-color);
    transition: all 0.25s linear;
    position: relative;
}

.router_link:visited{
    color: var(--font-color-secondary);
}

.router_link:before {
    content: "";
    color: #61a3ff;
    display: block;
    width: 100%;
    height: 3px;
    background-color: #61a3ff;
    position: absolute;
    left: 0;
    bottom: -3px; /* this is to match where the border is */
    transform-origin: left; 
    transform: scale(0);
    transition: 0.25s linear;
  /*   will-change: transform; */
  }
  
.router_link:hover:before {
    transform: scale(1);
  }

.drawer_container{
    position: absolute;
    left: 2rem;
}