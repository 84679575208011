:root{
  --fontColorPrimary : #1D201F;
  --fontColorAlternate : #0A2472;
  --fontColorLight : #F5F1ED;
  --customPanelBorder : #416788;
  --fontColorPrimaryLight : #303634;
  
  --font-color-primary : #010101;
  --font-color-secondary : #484847;
  --background-color-primary : #FAF1E4;
  --background-color-secondary : #F4F8FA;
  --accent-color : #9EB384;
  --accent-color-dark : #0F5809;
  --secondary-color : #CEDEBD;

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* this is the css for the blackTable component  */

