.bg-white {
  background-color: white;
}


html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


.p-4 {
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
  margin: 1rem 0;
  overflow-x: hidden;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.custom-table th {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
  background-color: #ffff;
  font-weight: bold;
}

.custom-table td {
  border: 1px solid #ccc;
  background-color: #ffff;
  padding: 8px;
}

.table-container {
  width: 100%;
  max-height: 70vh;
}

.table-container-2 {
  margin-top: 10px;
  width: 100%;
  max-height: 27vh;
  /* overflow-y: auto; */
  border: 1px solid #ddd;

}

.img-wrapper {
  height: 100vh;
  border: 1px solid #ddd;
}

@media (max-width: 768px) {
  .custom-table th,
  .custom-table td {
    font-size: 14px;
    padding: 6px;
  }
}
