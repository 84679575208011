.admin_info_label {
    color: var(--accent-color-dark);
    font-size: xx-large;
    font-family: "Lato", sans-serif;
    ;
}

.info_display_container {
    display: flex;
    flex-wrap: nowrap;
    gap: 4rem;
}

.single_digit_counter {
    color: var(--accent-color-dark);
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 1rem;
    box-sizing: border-box;
    border: var(--accent-color-dark) thin solid;
    width: 120px;
    height: 105px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.single_digit {
    font-family: "EB Garamond", serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 2rem;
}

.single_digit_text {
    text-transform: uppercase;
    text-align: center;
    text-wrap: nowrap;
    font-family: "Lato", sans-serif;
    ;
}

.single_digit_counter:hover {
    color: var(--background-color-secondary);
    background-color: var(--accent-color-dark);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.operations_div_container {
    padding: 1rem;
    box-sizing: border-box;
}

.stats_table_agrid {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    gap: "5px"
}

.admin_frame {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.user_crud-frame {
    width: 100%;
    height: 100%;
    padding-inline: 2rem;
    box-sizing: border-box;
}

.user_crud-card {
    padding: 15px;
    width: 100%;
    height: 100%;
    /* background: #e8f9ff00; */
    border: solid;
    border-color: var(--customPanelBorder);
    border-radius: 6px;
    border-width: thin;
    box-sizing: border-box;
}

.user_operation_container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 1rem;
}

.user-operation_label {
    font-family: "Lato", sans-serif;
    font-optical-sizing: auto;
    color: var(--fontColorPrimary);
    font-size: 25px;
}

.user_operations_divider {
    box-sizing: border-box;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-inline: 10%;
}

.user_operations_divider::after {
    content: '';
}

.user_detials_display_div {
    margin-top: 15px;
    font-weight: 700;
}