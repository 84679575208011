.pageImg{
    width: 100%;
    height: 100vh;
}

.table_red{
    background-color: rgb(219, 68, 68);
}

.table_non_sheet_val{
    background-color: rgb(197, 197, 197);
}

.table_green{
    background-color: rgb(56, 196, 72);
}

.table_warning{
    background-color: rgb(222, 163, 55);
}

.table_log_error{
    border: 2px solid rgb(255, 179, 0) !important;
}

.table_id{
    background-color: rgb(170, 170, 170);
}

.common_input_container{
    width: 100%;
    display: flex;
    padding-top: 0.5rem;
    gap: 5px;
}

.img_cntrl_btn{
    border-radius: 5px;
    margin-inline: 0.3vw;
    margin-bottom: 1rem;
    font-size: medium;
    padding: 10px;
    background-color: var(--accent-color-dark);
    color: whitesmoke;
    border: 0;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.img_cntrl_btn:hover{
    background-color: var(--accent-color);
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.img_cntrl_btn:disabled{
    background-color: rgb(141, 141, 141);
    color: rgb(211, 211, 211);
}

.image_container_div{
    position: relative;
}

.image_transform_tools{
    display: flex;
    height: 80vh;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: 2rem;
    z-index: 3;
    left: 0;
    opacity: 0.2;
    transition: 200ms ease;
}

.image_transform_tools:hover{
    opacity: 1;
    transition: 200ms ease;
}

.page_type_options{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    box-sizing: border-box;
    margin-inline: 5px;
    margin-top: 5px;
}

.details_subsection{
    /* border: solid thin rgb(20 20 137); */
    background-color: #2b2b3b15;
    box-sizing: border-box;
    padding: 0.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    margin-inline: 0.1rem;
    border-radius: 5px;
    min-height: 50px;
}

.toggle_subsection{
    color: var(--fontColorPrimary);
}